import Vue from 'vue';
import { MLAPI_ROOT } from '@/helpers/environment_variables';
import { MLAPIRequest, MLAPIUpdateToken } from '@/helpers/request';
import {
  apiGetSmartAdvisorProductions,
  apiGetPersonalizationDataByCropYearId,
  apiCreateAADProductions,
  apiDeleteAADProduction,
  apiCreateStorageConstraint,
  apiUpdateAADProduction,
  apiSyncAADProductions,
  apiUpdateStorageConstraint,
  apiDeleteStorageConstraint,
  apiFetchAADCommodities,
  apiGetAggregatedFarmProfileData,
  apiCreateSalePercentageGoals,
} from '@/api/aiAdvisor';
import cashFlowGoals from './cashflow-goals';

import { getDateTimestamp, getLocalEndOfPreviousDay } from '@/helpers/datetime';
import { roundUpTo } from '@/helpers/formatting';
import { monthsShort } from '@/data/time';

const MLAPI_ACTION_PLAN = 'action-plan';
const MLAPI_RISK_BASED_ACTION_PLANS = 'risk-based-action-plans';
const MLAPI_BEST_SALE = 'best-sale';
const MLAPI_SALE_POINTS = 'sale-points';
const MLAPI_SALE_POINTS_PERCENTS = 'detailed_backtest';
const MLAPI_HISTORIC_PADDED = 'historic-padded-summary';
const MLAPI_PROBABILITY_CONE = 'probability-cone';
const MLAPI_ARTICLES = 'article_endpoint';
const MLAPI_SUPPORTED = 'supported-parameters';

export function createMLUrl(APIRoot, APIEndpoint, APIVersion = 'v1') {
  return new URL(`${APIVersion}/${APIEndpoint}`, APIRoot);
}

export function MLTimezoneFix(timestamp) {
  return timestamp.slice(0, timestamp.indexOf('T'));
}

export default {
  state: () => ({
    riskProfile: null,
    cashFlowRevenue: 0,
    storageConstraint: {},
    selectedConstraint: null,
    preharvestSalePercentGoals: {},
    // FP Integration giving the amount sold of each AAD commodity
    soldAmountByAADCommodity: {
    },

    onboardingSelectedCommodities: [],
    farmProfileProductions: {},

    actionPlanData: {},
    seriesIDData: {},
    bestSaleData: {},
    probabilityConeData: {},
    historicPriceActualData: {},
    loadingHistoricPriceActualData: false,
    loadingSalePointsPercents: false,
    salePointsData: {},
    salePointsPercents: {},
    storageCapacity: null,
    unsold: null,
    saleContracts: {},
    seriesDataError: -1,
    seriesDataFetched: false,
    seriesDataSuccess: false,

    // The list of commodity names that ML API supports (ie: returns data for)
    MLCommodities: [],

    // Mapping of ML commodity name to readable display names
    AADCommodities: {},
    productions: {},

    // Which factor is selected to display detailed articles/timeseries
    selectedFactor: null,

    // Merge cash flow goals state
    ...cashFlowGoals.state(),
  }),

  getters: {
    isPersonalizationComplete: (state, getters, rootState) => ({
      portfolio: false,
      riskProfile: Object.keys(state.riskProfile || {}).length > 0,
      cashFlowGoal: Object.keys(state.cashFlowGoal || {}).length > 0,
      storageConstraint: Object.keys(state.storageConstraint || {}).length > 0,
    }),

    sortedStorageConstraintList: (state) => Object.values(state.storageConstraint || {})
      .sort((a, b) => new Date(a.date) - new Date(b.date)),

    getCommodityById: (state) => (id) => (state.AADCommodities[id]),

    getStoredAmountByCommodity: (state) => (commodityName) => (state.soldAmountByAADCommodity?.[commodityName] ?? 0),

    // TODO: This needs to be a "currentActionPlan" getter, which returns an action plan
    // recommendation that is relevant to the current 2-month time period. This will only be
    // possible once ML side has their time periods aligned with ours. For now 0th index is the
    // action plan recommendation which is best aligned to our 2-month time period.
    latestActionPlan: (state) => Object.entries(state.actionPlanData).reduce((acc, [key, val]) => {
      if (val?.length) {
        acc[key] = { ...val[0] };
      } else {
        acc[key] = {};
      }
      return acc;
    }, {}),

    selectedFactorSeriesData: (state) => {
      const seriesID = state.selectedFactor?.series_id;
      if (seriesID in state.seriesIDData) return state.seriesIDData[seriesID];

      return null;
    },

    commoditiesAvailableForOnboarding: (state) => Object.entries(state.AADCommodities).reduce((acc, [key, val]) => {
      if (state.MLCommodities.includes(val.name)) {
        acc[key] = val;
      }

      return acc;
    }, {}),

    // Commodities which do not yet have ML models / data to draw on but will in the future
    alertCommodities(state, getters) {
      return Object.values(state.AADCommodities)
        .filter((c) => !Object.values(getters.commoditiesAvailableForOnboarding).includes(c));
    },

    // AADCommodities that a user has selected during onboarding
    onboardingCommodities: (state) => Object.values(state.productions).map((production) => state.AADCommodities[production.commodity]),

    // An intersection of MLCommodities and onboardingCommodities, so that only these commodities
    // are available to be selected by the user
    commodities: (state, getters) => Object.entries(getters.onboardingCommodities).reduce((acc, [, val]) => {
      if (state.MLCommodities.includes(val.name)) {
        acc[val.id] = val;
      }
      return acc;
    }, {}),

    productionsSorted: (state) => Object.values(state.productions)
      .sort((a, b) => {
        const commodityA = state.AADCommodities[a.commodity].display_name;
        const commodityB = state.AADCommodities[b.commodity].display_name;
        return commodityA.localeCompare(commodityB);
      }),

    hasProductions: (state, getters) => getters.productionsSorted.length > 0,

    needsOnboarding: (state, getters) => getters.productionsSorted.length === 0,

    storageConstraintByCommodityId: (state) => (commodityId) => (Object.values(state.storageConstraint).find((element) => element.commodity === commodityId)),

    unsoldByCommodityName: (state) => (commodityName) => (Object.values(state.unsold).find((element) => element.commodity === commodityName)),

    personalizationParams: (state, getters, rootState, rootGetters) => {
      const result = {
        user_id: rootState.user?.id,
        risk_score: getters.isPersonalizationComplete.riskProfile ? state.riskProfile.calculated_score : 1,

        // TODO: FP integration
        storage_capacity: state.storageCapacity,
        unsold: state.unsold,
      };

      if (getters.isPersonalizationComplete.cashFlowGoal) {
        result.cashflow_revenue = Number(state.cashFlowGoal.target);
        result.cashflow_revenue_generated = Number(state.cashFlowRevenue);
        result.cashflow_start_date = getDateTimestamp(new Date(state.cashFlowGoal.creation_date));
        result.cashflow_deadline = getDateTimestamp(new Date(state.cashFlowGoal.date));
      }

      const defaultPreharvestSalePercent = rootGetters['smartAdvisor/isCurrentContext'] ? 0 : 20;
      result.crop_year = rootGetters['smartAdvisor/activeCropYear'].long_name;
      result.commodity_personalization = Object.values(getters.commodities).reduce((o, { name, id }) => ({
        ...o,
        [name]: {
          // Either these 3 exist together, or they aren't sent at all
          ...(getters.storageConstraintByCommodityId(id)) && {
            storage_goal: Number(getters.storageConstraintByCommodityId(id).target),
            storage_deadline: getDateTimestamp(new Date(getters.storageConstraintByCommodityId(id).date)),
            storage_start_date: getDateTimestamp(new Date(getters.storageConstraintByCommodityId(id).creation_date)),
          },
          // Discard the `commodity` in unsold since it's duplicate data,
          ...(getters.unsoldByCommodityName(name) ? (({ unsold_amount, unsold_percentage, total_production }) => ({ unsold_amount, unsold_percentage, total_production }))(getters.unsoldByCommodityName(name)) : {
            unsold_amount: 0,
            unsold_percentage: 0,
            total_production: 0,
          }),
          pre_harvest_sale_percentage: state.preharvestSalePercentGoals[id]?.target || defaultPreharvestSalePercent,
          sale_contracts: state.saleContracts[name] || [],
        },
      }), {});

      return result;
    },

    soldPercentageByAADCommodity: (state) => Object.entries(state.soldAmountByAADCommodity || {})
      .reduce((acc, [key, val]) => {
        // Use total_production that is sent up with unsold since it is converted to the same unit
        const producedAmount = state.unsold.find((entry) => entry.commodity === key)?.total_production;
        acc[key] = (val / producedAmount) * 100.0 || 0;

        return acc;
      }, {}),

    monthRanges: (state, getters, rootState, rootGetters) => {
      const cropYear = rootGetters['shared/currentCropYear'];
      const [startYear] = cropYear.start.split('-');

      const monthRanges = [];
      for (let monthIndex = 1; monthIndex < 18; monthIndex += 2) {
        const start = new Date(Date.UTC(startYear, monthIndex, 1));
        const end = new Date(Date.UTC(startYear, monthIndex + 2, 1));
        end.setUTCMilliseconds(-1);

        const startMonthName = monthsShort[start.getUTCMonth()];
        const endMonthName = monthsShort[end.getUTCMonth()];

        monthRanges.push({
          startMonth: startMonthName,
          startTimestamp: getDateTimestamp(start),
          timePeriodStartDate: start,
          endMonth: endMonthName,
          endTimestamp: getDateTimestamp(end),
          timePeriodEndDate: end,
        });
      }
      return monthRanges;
    },

    commodityPriceRange: (state, getters) => Object.values(getters.commodities).reduce((priceRanges, commodity) => {
      Object.assign(priceRanges, { [commodity.name]: {} });

      const saleDate = state.bestSaleData?.[commodity.name]?.['saleDate'];

      // If there is data for the best sale data available in probabilityConeData
      if (state.probabilityConeData?.[commodity.name]?.['high']?.[saleDate] !== undefined) {
        Object.assign(priceRanges[commodity.name], {
          high: state.probabilityConeData?.[commodity.name]?.['high']?.[saleDate],
          median: state.probabilityConeData?.[commodity.name]?.['predicted']?.[saleDate],
          low: state.probabilityConeData?.[commodity.name]?.['low']?.[saleDate],
        });
        return priceRanges;
      }
      // Otherwise, look for the price in historic actuals
      Object.assign(priceRanges[commodity.name], {
        high: state.historicPriceActualData?.[commodity.name][saleDate],
        median: state.historicPriceActualData?.[commodity.name]?.[saleDate],
        low: state.historicPriceActualData?.[commodity.name]?.[saleDate],
      });
      return priceRanges;
    }, {}),

    actionPlanFeasible: (state) => !(state.actionPlanData?.errors || []).some((error) => error.message === 'INFEASIBLE' || error.message === 'GENERIC_ERROR'),

    isFactorDetailsOpen: (state) => state.selectedFactor !== null,

    // Merge cash flow goals getters
    ...cashFlowGoals.getters,
  },

  mutations: {
    setRiskProfile(state, riskProfile) {
      state.riskProfile = riskProfile;
    },
    setCashFlowRevenue(state, cashFlowRevenue) {
      state.cashFlowRevenue = cashFlowRevenue;
    },
    setPreharvestSalePercentGoals(state, preharvestSalePercentGoals) {
      state.preharvestSalePercentGoals = preharvestSalePercentGoals || {};
    },
    setStorageConstraint(state, storageConstraint) {
      Vue.set(state.storageConstraint, storageConstraint.id, storageConstraint);
    },
    deleteStorageConstraint(state, id) {
      Vue.delete(state.storageConstraint, id);
    },
    setAllStorageConstraints(state, storageConstraints) {
      // there's a chance of null being sent, hence the fallback
      state.storageConstraint = (storageConstraints || []).reduce((acc, x) => ({ ...acc, [x.id]: x }), {});
    },
    setStorageCapacity(state, storageCapacity) {
      state.storageCapacity = storageCapacity;
    },
    setUnsoldData(state, unsoldData) {
      state.unsold = unsoldData;
    },
    setSaleContracts(state, saleContracts) {
      state.saleContracts = saleContracts || {};
    },
    setSoldAmountByAADCommodity(state, soldAmountByAADCommodity) {
      state.soldAmountByAADCommodity = soldAmountByAADCommodity || {};
    },
    setAADCommodities(state, commodities) {
      state.AADCommodities = commodities || {};
    },
    setProductions(state, productions) {
      state.productions = productions;
    },
    updateProductions(state, productions) {
      state.productions = { ...state.productions, ...productions };
    },
    deleteProduction(state, production) {
      Vue.delete(state.productions, production.id);
    },
    setActionPlanData(state, payload) {
      state.actionPlanData = payload || {};
    },
    setSeriesIDData(state, { seriesID, data }) {
      Vue.set(state.seriesIDData, seriesID, data);
    },
    setBestSaleData(state, data) {
      state.bestSaleData = data || {};
    },
    setHistoricPriceActualData(state, data) {
      state.historicPriceActualData = data || {};
    },
    combineHistoricPriceActualData(state, data) {
      const commodities = Object.keys(data);
      commodities.forEach((commodity) => {
        if (!(commodity in state.historicPriceActualData)) {
          state.historicPriceActualData[commodity] = data[commodity];
        } else {
          Object.assign(state.historicPriceActualData[commodity], data[commodity]);
        }
      });
    },
    setLoadingHistoricPriceActualData(state, loading) {
      state.loadingHistoricPriceActualData = loading;
    },
    setLoadingSalePointsPercents(state, loading) {
      state.loadingSalePointsPercents = loading;
    },
    setProbabilityConeData(state, data) {
      state.probabilityConeData = data || {};
    },
    setMLCommodities(state, commodities) {
      state.MLCommodities = commodities || [];
    },
    setSalePointsData(state, data) {
      state.salePointsData = data || {};
    },
    setSalePointsPercents(state, data) {
      state.salePointsPercents = data || {};
    },
    combineSalePointsMultipleYears(state, data) {
      if (!Object.keys(state.salePointsPercents).length) {
        state.salePointsPercents = {
          entries: {},
          avg_price: {},
          avg_smart_advisor_price: {},
        };
      }
      const keys = Object.keys(data);
      keys.forEach((key) => {
        Object.assign(state.salePointsPercents[key], data[key]);
      });
    },
    setOnboardingSelectedCommodities(state, data) {
      state.onboardingSelectedCommodities = data || [];
    },
    setFarmProfileProductions(state, data) {
      state.farmProfileProductions = data || {};
    },
    setSelectedFactor(state, data) {
      state.selectedFactor = data;
    },
    setSeriesDataFetched(state, fetched) {
      state.seriesDataFetched = fetched;
    },
    setSeriesDataSuccess(state, success) {
      state.seriesDataSuccess = success;
    },
    setSelectedConstraint(state, constraint) {
      state.selectedConstraint = constraint;
    },

    // Merge cash flow goals mutations
    ...cashFlowGoals.mutations,
  },

  actions: {
    async updateToken() {
      await MLAPIUpdateToken();
    },

    async getActionPlanData({ commit, getters }, { commodities }) {
      const url = createMLUrl(MLAPI_ROOT, MLAPI_ACTION_PLAN);

      try {
        const params = {
          commodities,
          ...getters.personalizationParams,
        };

        const data = await MLAPIRequest(url.href, params);

        commit('setActionPlanData', data);

        return data;
      } catch (e) {
        this._vm.$snackbar.error(e);
        return null;
      }
    },

    /**
     * This action will call the action plan endpoint with hypothetical personalization params.
     * The personalization overrides should include the constraint or goal whose effect we are
     * trying to measure. Since we're checking before we create anything, the constraint/goal
     * will not yet exist in the personalization params, hence the personalization overrides.
     * @param {Object} context
     * @param {Object} payload
     * @returns {Boolean}
     */
    async validateActionPlanFeasibility({ getters }, { commodities, personalizationOverrides }) {
      const url = createMLUrl(MLAPI_ROOT, MLAPI_ACTION_PLAN);

      const params = {
        commodities,
        ...getters.personalizationParams,
        ...personalizationOverrides,
      };

      const actionPlan = await MLAPIRequest(url.href, params);
      const isFeasible = !(actionPlan?.errors || []).map((error) => error.message).some((error) => ['INFEASIBLE', 'GENERIC_ERROR'].includes(error));
      return isFeasible;
    },

    async getSeriesData({ commit }, { seriesID }) {
      const url = createMLUrl(MLAPI_ROOT, MLAPI_ARTICLES);

      try {
        const params = {
          series_id: seriesID,
        };

        const data = await MLAPIRequest(url?.href, params);
        commit('setSeriesIDData', { seriesID, data });
        commit('setSeriesDataFetched', true);
        commit('setSeriesDataSuccess', true);

        return data;
      } catch (e) {
        this._vm.$snackbar.error(e);
        commit('setSeriesDataFetched', true);
        commit('setSeriesDataSuccess', false);

        return null;
      }
    },

    async getBestSaleData({ commit, getters }, { commodities }) {
      const url = createMLUrl(MLAPI_ROOT, MLAPI_BEST_SALE);

      try {
        const params = {
          commodities,
          ...getters.personalizationParams,
        };

        const response = await MLAPIRequest(url.href, params);

        // Fix timestamp to be in the user's timezone
        const data = Object.keys(response).filter((key) => key.indexOf('error') === -1).reduce((acc, key) => {
          acc[key] = {
            prediction: {},
            salePercentage: 0,
            saleDate: null,
            bestPrice: null,
          };

          Object.entries(response[key].predictions).forEach(([time, price]) => {
            acc[key].prediction[MLTimezoneFix(time)] = price;
          });

          acc[key].salePercentage = response[key].sale_percentage;
          acc[key].saleDate = MLTimezoneFix(response[key].signal.date);
          acc[key].bestPrice = response[key].best_price;

          return acc;
        }, {});

        commit('setBestSaleData', data);

        return data;
      } catch (e) {
        this._vm.$snackbar.error(e);
        return null;
      }
    },

    async getSupportedParameters({ commit }) {
      const url = createMLUrl(MLAPI_ROOT, MLAPI_SUPPORTED);

      try {
        const { commodities } = await MLAPIRequest(url.href);

        commit('setMLCommodities', commodities);

        return commodities;
      } catch (e) {
        this._vm.$snackbar.error(e);
        return null;
      }
    },

    async getSalePointsData({ commit }, { commodities }) {
      const url = createMLUrl(MLAPI_ROOT, MLAPI_SALE_POINTS);

      try {
        const { signals } = await MLAPIRequest(url.href, {
          commodities,
        });

        // Fix timestamp to be in the user's timezone
        const data = Object.keys(signals || {}).reduce((acc, key) => {
          acc[key] = {};
          Object.entries(signals[key]).forEach(([time, price]) => {
            acc[key][MLTimezoneFix(time)] = price;
          });

          return acc;
        }, {});

        commit('setSalePointsData', data);

        return data;
      } catch (e) {
        this._vm.$snackbar.error(e);
        return null;
      }
    },
    async getSalePointsPercents({ commit }, { commodities, cropYear }) {
      const url = createMLUrl(MLAPI_ROOT, MLAPI_SALE_POINTS_PERCENTS);

      try {
        const { signals, avg_price, avg_smart_advisor_price } = await MLAPIRequest(url.href, {
          commodities,
          crop_year: cropYear,
        });

        // Fix timestamp to be in the user's timezone
        const percents = Object.keys(signals || {}).reduce((acc, key) => {
          acc[key] = {};
          Object.entries(signals[key]).forEach(([time, price]) => {
            acc[key][MLTimezoneFix(time)] = price;
          });

          return acc;
        }, {});

        commit('setSalePointsPercents', { percents, avg_price, avg_smart_advisor_price });

        return { percents, avg_price, avg_smart_advisor_price };
      } catch (e) {
        this._vm.$snackbar.error(e);
        return null;
      }
    },
    async getSalePointsPercentsMultipleYears({ commit }, { commodities, cropYears }) {
      const url = createMLUrl(MLAPI_ROOT, MLAPI_SALE_POINTS_PERCENTS);

      const entriesMap = new Map();
      const avgPriceMap = new Map();
      const avgSmartAdvisorPriceMap = new Map();
      try {
        commit('setLoadingSalePointsPercents', true);
        const data = await Promise.all(cropYears
          .map(async (cropYear) => {
            const { signals, avg_price, avg_smart_advisor_price } = await MLAPIRequest(url.href, {
              commodities,
              crop_year: cropYear,
            });
            return {
              signals,
              avg_price,
              avg_smart_advisor_price,
              cropYear,
            };
          }));

        data.forEach(({
          signals,
          avg_price,
          avg_smart_advisor_price,
          cropYear,
        }) => {
          // Fix timestamp to be in the user's timezone
          const tempPercents = Object.keys(signals || {}).reduce((acc, key) => {
            acc[key] = {};
            Object.entries(signals[key]).forEach(([time, price]) => {
              acc[key][MLTimezoneFix(time)] = price;
            });

            return acc;
          }, {});

          Object.keys(tempPercents).forEach((key) => {
            Object.keys(tempPercents[key]).forEach((time) => {
              entriesMap.set(time, tempPercents[key][time]);
            });
          });

          Object.keys(avg_price).forEach((key) => {
            avgPriceMap.set(cropYear, avg_price[key]);
          });

          Object.keys(avg_smart_advisor_price).forEach((key) => {
            avgSmartAdvisorPriceMap.set(cropYear, avg_smart_advisor_price[key]);
          });
        });

        const entries = Object.fromEntries(entriesMap);
        const avg_price = (Object.fromEntries(avgPriceMap));
        const avg_smart_advisor_price = (Object.fromEntries(avgSmartAdvisorPriceMap));
        commit('combineSalePointsMultipleYears', { entries, avg_price, avg_smart_advisor_price });
        commit('setLoadingSalePointsPercents', false);
        return { entries, avg_price, avg_smart_advisor_price };
      } catch (e) {
        commit('setLoadingSalePointsPercents', false);
        this._vm.$snackbar.error(e);
        return null;
      }
    },
    async getProbabilityConeData({
      commit, rootGetters,
    }, { commodities }) {
      const cropYear = rootGetters['shared/currentCropYear'];
      const url = createMLUrl(MLAPI_ROOT, MLAPI_PROBABILITY_CONE);

      // Current crop year uses default behaviour, without start/end times
      // Next crop year specifies a full crop year and gets monthly data
      const payload = { commodities };
      const isCurrentContext = rootGetters['smartAdvisor/isCurrentContext'];
      if (!isCurrentContext) {
        const [startYear, startMonth, startDate] = cropYear.start.split('-');
        const startTime = new Date(Date.UTC(startYear, startMonth - 1, startDate));
        const [endYear, endMonth, endDate] = cropYear.end.split('-');
        const endTime = new Date(Date.UTC(endYear, endMonth - 1, endDate - 1));

        payload.start_time = getDateTimestamp(startTime);
        payload.end_time = getDateTimestamp(endTime);
        payload.long_term = true;
      }

      try {
        const response = await MLAPIRequest(url.href, payload);

        // Fix timestamp to be in the user's timezone
        const data = Object.keys(response || {}).reduce((acc, key) => {
          acc[key] = {
            high: {},
            predicted: {},
            low: {},
          };

          ['high', 'predicted', 'low'].forEach((type) => {
            Object.entries(response[key][type]).forEach(([time, price]) => {
              acc[key][type][MLTimezoneFix(time)] = price;
            });
          });

          return acc;
        }, {});

        commit('setProbabilityConeData', data);

        return data;
      } catch (e) {
        this._vm.$snackbar.error(e);
        return null;
      }
    },

    async getHistoricPaddedSummary({ commit }, { commodities }) {
      const url = createMLUrl(MLAPI_ROOT, MLAPI_HISTORIC_PADDED);

      try {
        const { price } = await MLAPIRequest(url.href, {
          commodities,
        });

        const data = Object.keys(price || {}).reduce((acc, key) => {
          acc[key] = {};
          Object.entries(price[key]).forEach(([time, priceVal]) => {
            acc[key][MLTimezoneFix(time)] = priceVal;
          });

          return acc;
        }, {});

        commit('setHistoricPriceActualData', data);

        return data;
      } catch (e) {
        this._vm.$snackbar.error(e);
        return null;
      }
    },

    async getRiskBasedActionPlans({ getters }, { commodities, riskScores }) {
      const url = createMLUrl(MLAPI_ROOT, MLAPI_RISK_BASED_ACTION_PLANS);
      const payload = {
        commodities,
        ...getters.personalizationParams,
        risk_score: riskScores,
      };

      try {
        const response = await MLAPIRequest(url.href, payload);
        return response;
      } catch (e) {
        this._vm.$snackbar.error(e);
        return {};
      }
    },

    async getHistoricPaddedSummarySpecificRange({ commit }, { commodities, start_time, end_time }) {
      const url = createMLUrl(MLAPI_ROOT, MLAPI_HISTORIC_PADDED);

      try {
        commit('setLoadingHistoricPriceActualData', true);
        const { price } = await MLAPIRequest(url.href, {
          commodities,
          start_time,
          end_time,
        });

        const data = Object.keys(price || {}).reduce((acc, key) => {
          acc[key] = {};
          Object.entries(price[key]).forEach(([time, priceVal]) => {
            acc[key][MLTimezoneFix(time)] = priceVal;
          });

          return acc;
        }, {});

        commit('combineHistoricPriceActualData', data);
        commit('setLoadingHistoricPriceActualData', false);
        return data;
      } catch (e) {
        commit('setLoadingHistoricPriceActualData', false);
        this._vm.$snackbar.error(e);
        return null;
      }
    },

    async getPersonalizationDataByCropYearId({ commit }, cropYearId) {
      try {
        const { data } = await apiGetPersonalizationDataByCropYearId(cropYearId);
        commit('riskProfile/setRiskProfileResults', data.risk_profile, { root: true });
        commit('setRiskProfile', data.risk_profile);
        commit('setCashFlowGoals', data.cash_flow_goals);
        commit('setCashFlowGoal', Object.values(data.cash_flow_goals || {})[0]);
        commit('setCashFlowRevenue', data.cash_flow_revenue);
        commit('setAllStorageConstraints', data.storage_constraints);
        commit('setPreharvestSalePercentGoals', data.preharvest_sale_percent_goals);
        commit('setStorageCapacity', data.storage_capacity);
        commit('setUnsoldData', data.unsold);
        commit('setSaleContracts', data.sale_contracts);
        commit('setSoldAmountByAADCommodity', data.inventory_sold);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async createAADSalePercentageGoals({ commit }, payload) {
      try {
        const { data, message } = await apiCreateSalePercentageGoals(payload);
        this._vm.$snackbar.success(message);
        return data;
      } catch (e) {
        this._vm.$snackbar.error(e);
        return null;
      }
    },

    async getSmartAdvisorProductions({ commit, rootGetters }) {
      try {
        const cropYear = rootGetters['smartAdvisor/productionCropYear'];
        if (!cropYear) throw new Error('Crop year not found');

        const { results } = await apiGetSmartAdvisorProductions({
          crop_year: cropYear.id,
        });
        commit('setProductions', results);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async createAADProduction({ commit }, payload) {
      try {
        const { data, message } = await apiCreateAADProductions([payload]);
        commit('updateProductions', data);

        this._vm.$snackbar.success(message);
        return data;
      } catch (e) {
        this._vm.$snackbar.error(e);
        return null;
      }
    },

    async createAADProductions({ commit }, payload) {
      try {
        const { data, message } = await apiCreateAADProductions(payload);
        commit('setProductions', data);

        this._vm.$snackbar.success(message);
        return data;
      } catch (e) {
        this._vm.$snackbar.error(e);
        return null;
      }
    },

    async updateAADProduction({ commit, state }, production) {
      try {
        const { data, message } = await apiUpdateAADProduction(production);
        commit('updateProductions', data);

        this._vm.$snackbar.success(message);
        return data;
      } catch (e) {
        this._vm.$snackbar.error(e);
        return null;
      }
    },

    async syncAADProduction({ commit, state }, { production, unit }) {
      try {
        if (state.farmProfileProductions[production.commodity] === undefined) {
          throw Error('No data to sync for this production.');
        }
        const payload = {
          ...production,
          production: roundUpTo(state.farmProfileProductions[production.commodity]),
          production_unit: unit,
        };

        const { data } = await apiUpdateAADProduction(payload);
        commit('updateProductions', data);

        this._vm.$snackbar.success('Synced data.');
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async syncEveryAADProduction({ commit }, { unitID, cropYearID }) {
      try {
        const payload = {
          unit: unitID,
          crop_year: cropYearID,
        };
        const { data } = await apiSyncAADProductions(payload);
        commit('setProductions', data);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async deleteAADProduction({ commit }, production) {
      try {
        await apiDeleteAADProduction(production);
        commit('deleteProduction', production);

        this._vm.$snackbar.success('Production deleted.');
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async createStorageConstraint({ commit }, payload) {
      try {
        const { data, message } = await apiCreateStorageConstraint(payload);
        commit('setStorageConstraint', data);
        this._vm.$snackbar.success(message);
      } catch (e) {
        this._vm.$snackbar.error('Unable to create storage constraint.');
      }
    },

    async updateStorageConstraint({ commit }, payload) {
      try {
        const { data, message } = await apiUpdateStorageConstraint(payload);
        commit('setStorageConstraint', data);
        this._vm.$snackbar.success(message);
      } catch (e) {
        this._vm.$snackbar.error('Unable to update storage constraint.');
      }
    },

    async deleteStorageConstraint({ commit }, id) {
      try {
        const { message } = await apiDeleteStorageConstraint(id);

        commit('deleteStorageConstraint', id);

        this._vm.$snackbar.success(message);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async fetchAADCommodities({ commit }) {
      try {
        const { data } = await apiFetchAADCommodities();

        commit('setAADCommodities', data);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async getAggregatedFarmProfileData({ commit, getters }, { cropYearId, unitId }) {
      try {
        const { data } = await apiGetAggregatedFarmProfileData(cropYearId, unitId);

        const onboardingSelectedCommodities = Object.keys(data).reduce((acc, id) => {
          if (id in getters.commoditiesAvailableForOnboarding) {
            acc.push(getters.commoditiesAvailableForOnboarding[id]);
          }
          return acc;
        }, []);

        commit('setFarmProfileProductions', data);
        commit('setOnboardingSelectedCommodities', onboardingSelectedCommodities);

        return onboardingSelectedCommodities;
      } catch (e) {
        this._vm.$snackbar.error(e);
        return [];
      }
    },

    openFactorDetails({ commit }, factor) {
      commit('setSelectedFactor', factor);
    },

    closeFactorDetails({ commit }) {
      commit('setSelectedFactor', null);
    },

    resetSeriesDataFetched({ commit }) {
      commit('setSeriesDataFetched', false);
    },

    resetSalePointsPercents({ commit }) {
      commit('setSalePointsPercents', {});
    },

    // Merge cash flow goals actions
    ...cashFlowGoals.actions,
  },

  namespaced: true,
};
